import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Back from '../../components/caseStudies/back'
import BrandBox from '../../components/caseStudies/brandBox'
import ContentWrapper from '../../components/share/contentWrapper'
import Details from '../../components/caseStudies/details'
import Header from '../../components/caseStudies/header'
import IntroRow from '../../components/share/introRow'
import Layout from '../../components/layout'
import Quote from '../../components/share/quote'
import SpeakToTeam from '../../components/share/speakToTeam'
import TypeFormButton from '../../components/share/typeFormButton'

import brandIcon from '../../images/brandLogos/barryPlant.svg'

const query = graphql`
  query {
    header: file(relativePath: { eq: "caseStudies/header-barryplant.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agentImage: file(
      relativePath: { eq: "brandAgentIcon/doug_hutchinson.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const BarryPlant = props => {
  return (
    <Layout>
      <StaticQuery
        query={query}
        render={data => (
          <Fragment>
            <Header
              background={data.header.childImageSharp.fluid}
              year="1958"
              iconStyle={{ width: '140px' }}
              url="www.barryplant.com.au"
              brandName="Barry Plant Group"
              icon={brandIcon}
              overlayColor="linear-gradient(117.3deg, rgba(33, 61, 161, 0.5) 49.65%, rgba(255, 71, 126, 0.5) 103.61%)"
              content={
                <Fragment>
                  Humble beginnings in 1958 through to controlling over 75
                  Melbourne offices and far reaching offices across South
                  Australia and Queensland. From 50 disjointed systems to 1
                  ultimate platform.
                </Fragment>
              }
            />

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Details
                sizes={['National', 'Franchise Group']}
                integrations={['CRMs', 'Pinters', 'Newspapers']}
                customTitle="# OF SALES IN 2018"
                customData={['12,000+']}
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                descStyle={{ width: '100%' }}
                desc={`“We can already see the improvements and insights in our business by using Realhub as a platform for all our offices.”`}
              />
              <IntroRow
                descWidth="85%"
                title="Combining and consolidating 75 offices, across 25 different platforms. Into 1 cohesive offering."
                content={
                  <Fragment>
                    <Paragraph>
                      Growth for real estate offices in the past, meant opening
                      a new office, and finding all new suppliers that could
                      service your areas. Each new supplier meant a new platform
                      to learn to order, and create marketing.
                    </Paragraph>
                    <Paragraph>
                      Along with this, every new office brought their own set of
                      workflows and processes, and what worked best in their
                      market. Consolidating suppliers and platforms was a
                      primary requirement of the brand group to get better
                      oversight of their entire companies marketing efforts.
                    </Paragraph>
                    <Paragraph>
                      By moving to Realhub, not only have their support staff
                      experiences a 50%+ improvement in speed of marketing
                      creation and learning but each offices unique workflows
                      and processes were able to be accommodated with a
                      versatile, enterprise level software.
                    </Paragraph>
                    <Paragraph>
                      With the initial testing rollout a resounding success, and
                      the benefits for the group as a whole immediately evident
                      the brand-wide rollout was undertaken.
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                desc={
                  <Fragment>
                    <Paragraph style={{ fontFamily: 'Caveat' }}>
                      “Realhub offers effective collaboration and a much greater
                      level of support of scale. Realhub has become a part of
                      our business and our team, working closely with our
                      corporate marketing team and our offices in a
                      collaborative environment.
                    </Paragraph>

                    <Paragraph style={{ fontFamily: 'Caveat' }}>
                      There’s a lot of change occurring and we can already see
                      the improvements in our business and the insights was are
                      gaining is increasing our efficiency nad offering new
                      opportunities.
                    </Paragraph>
                    <Paragraph style={{ fontFamily: 'Caveat' }}>
                      Aligned with our business, and our philosophy, Realhub
                      removed prior inefficiencies across the group that relying
                      on a multitude of suppliers contained. We’re eliminating
                      waste through the adoption of Realhub. And that is what's
                      going to drive value in our business.”
                    </Paragraph>
                  </Fragment>
                }
                descStyle={{ width: '100%' }}
                agentImage={data.agentImage.childImageSharp.fluid}
                agentName="Doug Hutchinson"
                agencyName="BarryPlant"
                title="Operations Manager"
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <IntroRow
                descWidth="65%"
                content={
                  <Fragment>
                    <Paragraph>
                      Built for versatility, and experts in migration we’ve
                      worked alongside the head office team, and each offices
                      own individual teams to create more efficient systems and
                      processes and improved the brand’s ability to track and
                      visualise their offices unique challenges. Addressing and
                      improving on their issues every day.
                    </Paragraph>
                    <Paragraph>
                      The creation of marketing assets is one piece of a
                      complicated puzzle. If your current software isn’t
                      providing broad advances in your ability to run a business
                      it’s time to contact the team at Realhub.
                      <TypeFormButton
                        text="Join the team at Realhub today."
                        plain
                      />
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                descStyle={{ width: '100%' }}
                desc="“I'm excited for what the future holds for our businesses together”"
              />
            </ContentWrapper>

            <ContentWrapper>
              <SpeakToTeam
                uid="WpgxlK"
                avatar="https://labs-assets.typeform.com/cui/avatars/WpgxlK/cde0f362a9e74bff7bfc.png"
              />
            </ContentWrapper>
            <ContentWrapper style={{ margin: '40px auto' }}>
              <Back />
              <Flex>
                <BrandBox
                  brandName="BelleProperty"
                  iconStyle={{ height: '50px' }}
                />

                <BrandBox brandName="Cunninghams" />

                <BrandBox brandName="TheAgency" />
              </Flex>
            </ContentWrapper>
          </Fragment>
        )}
      />
    </Layout>
  )
}

export default BarryPlant

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
